import Vue from 'vue'
import router from '@/router'
import store from '@/store'

// 订单状态
export function getOrderStatus(status){
  const orderStatusMap = {
    '0': '已报单',
    '1': '已签约',
    '2': '已放款',
    '-1': '无意向'
  }
  return orderStatusMap[`${status}`] || ''
}

/**
 * 获取uuid
 */
export function getUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
  })
}

/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth(key) {
  return JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = 'id', pid = 'parentId') {
  var res = []
  var temp = {}
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]['children']) {
        temp[data[k][pid]]['children'] = []
      }
      if (!temp[data[k][pid]]['_level']) {
        temp[data[k][pid]]['_level'] = 1
      }
      data[k]['_level'] = temp[data[k][pid]]._level + 1
      temp[data[k][pid]]['children'].push(data[k])
    } else {
      res.push(data[k])
    }
  }
  return res
}

/**
 * 清除登录信息
 */
export function clearLoginInfo() {
  Vue.cookie.delete('token')
  router.options.isAddDynamicMenuRoutes = false
}
export function isArray(arr) {
  return Array.isArray(arr)
}
export function isObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]'
}
// 数字都非空
export function isNotEmpty(value) {
  if (value === null || value === undefined) {
    return false
  }
  if (typeof value === 'string') {
    return !!value
  }
  if (typeof value === 'boolean') {
    return value
  }
  if (typeof value === 'number') {
    return true
  }
  if (isArray(value)) {
    return !!value.length
  }
  if (isObject(value)) {
    return !!Object.keys(value).length
  }
  return false

}
